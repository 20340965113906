(function(){
'use strict';

teammatesCtrl.$inject = ["$q", "$scope", "$http", "scFundraisingTeamsService", "scFlowModalService", "scUserAgent", "scResponsiveWidth", "scProgressDataFactory"];
function teammatesCtrl($q, $scope, $http, scFundraisingTeamsService, scFlowModalService, scUserAgent, scResponsiveWidth, scProgressDataFactory) {
  $scope.teamsEndpoint = '/fundraising-teams/' + scFundraisingTeamsService.active.current.id + '/fundraising-pages?with=member&aggregates=true';

  $scope.teamsEndpointQuery = {};
  $scope.mailto = scUserAgent.isMobile();
  $scope.sortBy.goal = 'desc';
  $scope.activeTab = 'goal';
  $scope.isMobile = scResponsiveWidth.isMobile;

  $scope.totalMembers = _.get(scFundraisingTeamsService, 'active.current.pages_count_active');

  $scope.dataFunction = function (pages) {
    $scope.itemsSelected = {};
    $scope.selectedFundraisers = 0;
    $scope.saveList = true;
    $scope.pages = pages;
    // this is used to control when the list of teammates are added to the DOM
    // because initially, on a higher scope, $scope.page is populated with other
    // pages... lol
    $scope.firstLoad = true;
    return pages;
  };

  $scope.isFocused = false;

  $scope.focused = function () {
    $scope.isFocused = true;
  };

  $scope.blurred = function () {
    $scope.isFocused = false;
  };

  $scope.closeModal = function () {
    scFlowModalService.close();
  };

  $scope.inviteStyle = {
    borderColor: $scope.theme.styles.primaryColor,
    color: $scope.theme.styles.primaryColor
  };

  $scope.selected = {};
  $scope.tooltipPosition = { my: 'bottom center', at: 'top center' };

  /**
   * Select members
   */
  $scope.selectAll = function () {
    angular.forEach($scope.pages, function (item) {
      item.selected = $scope.itemsSelected.selectedAll;
    });
    $scope.selectedFundraisers = $scope.itemsSelected.selectedAll ? $scope.pages.length : 0;
  };

  $scope.toggleCheckbox = function (id, $event) {
    $event.stopPropagation();
    var pageIndex = _.findIndex($scope.pages, function (page) {
      return page.id == id;
    });

    var isSelected = $scope.pages[pageIndex].selected;
    if (isSelected) {
      $scope.pages[pageIndex].selected = false;
    } else {
      $scope.pages[pageIndex].selected = true;
    }

    $scope.itemsSelected.selectedAll = $scope.pages.every(function (item) {
      return item.selected == true;
    });

    $scope.selectedFundraisers = getSelectedFundraisers().length;
  };

  function setTotalRaisedAndCurrency() {
    _.forEach($scope.pages, function (page) {
      var pageProgress = scProgressDataFactory.getEntityProgress(page);
      page.display_total_raised = pageProgress.totalRaised;
      page.display_currency = pageProgress.currency;
    });
  }

  /**
   * Save the current list of posts on every initial page change,
   * so that when clearing the search, we can display the correct page
   */
  $scope.$watch('saveList', function (newVal) {
    if (newVal) {
      setTotalRaisedAndCurrency();

      $scope.defaultPages = _.cloneDeep($scope.pages);
      $scope.saveList = false;
    }
  });

  $scope.searchTeammates = {
    filter: ''
  };

  var canceler = void 0;

  $scope.$watch('searchTeammates.filter', function (newVal, oldVal) {
    if (newVal !== oldVal) {
      // always cancel previous requests
      if (canceler) {
        canceler.resolve();
      }

      if (newVal !== '') {
        $scope.loading = true;
        canceler = $q.defer();
        var endpoint = '/frs-api' + $scope.teamsEndpoint;

        $http({
          url: endpoint,
          method: 'GET',
          params: {
            filter: 'alias*=' + newVal
          },
          timeout: canceler.promise
        }).then(function (response) {
          if (response.data.data.length < 1) {
            $scope.hidePagination = true;
          }
          $scope.loading = false;
          $scope.pages = response.data.data;
          setTotalRaisedAndCurrency();
          $scope.saveList = false;
        });
      } else {
        // User clears search bar after use; display the correct fundraising pages
        $scope.pages = $scope.defaultPages;
        $scope.loading = false;
      }
    }
  });

  function getSelectedFundraisers() {
    var selectedMembers = $.grep($scope.pages, function (page) {
      return page.selected;
    });

    return selectedMembers;
  }

  $scope.makeCaptain = function () {
    var members = getSelectedFundraisers();

    _.forEach(members, function (member) {
      if (member.id) {
        $http({
          url: '/frs-api/fundraising-pages' + member.id,
          method: 'PUT',
          data: { team_role: 'co-captain' }
        }).then(function () {}, function () {});
      }
    });
  };

  $scope.copyEmailAddresses = function () {
    $scope.teammateEmails = [];
    $scope.selectedPages = getSelectedFundraisers();

    _.forEach($scope.selectedPages, function (page) {
      if (page.member && page.member.email_address) {
        $scope.teammateEmails.push(page.member.email_address);
      }
    });

    $scope.teammateEmails = $scope.teammateEmails.join();

    scFlowModalService.open({
      templateUrl: 'frs/shared/supporter/pages/teammates/copy-emails-modal/template',
      maxWidth: 520,
      context: $scope
    });
  };

  // Subteams && member leaderboards
  $scope.team.getSubteams().then(function (response) {
    $scope.subteams = _.get(response, 'data.data', {});
    $scope.subteams.total = _.get(response, 'data.total', 0);
  });
}

angular.module('classy.frs').controller('teammatesCtrl', teammatesCtrl);
})();