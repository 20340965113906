(function(){
'use strict';

FundraisingPageDetailsCtrl.$inject = ["$scope", "$log", "$location", "$sce", "$timeout", "$q", "$http", "scPagesService", "scMembersService", "scFundraisingPagesService", "scCampaignsService", "scBlocksService", "scImg", "scFlowModalService", "scFroalaOptions", "scTimeMachineService", "scBanner", "scDomainsService", "scAppealSetService"];
function FundraisingPageDetailsCtrl($scope, $log, $location, $sce, $timeout, $q, $http, scPagesService, scMembersService, scFundraisingPagesService, scCampaignsService, scBlocksService, scImg, scFlowModalService, scFroalaOptions, scTimeMachineService, scBanner, scDomainsService, scAppealSetService) {
  var preferences = void 0;
  var oldSlugObj = void 0;
  var hadSlug = void 0;

  $scope.IS_TICKETED = scCampaignsService.active.IS_TICKETED;
  $scope.block = scBlocksService.getBlockByType('fundraiser');

  $scope.fundraisingPage = scFundraisingPagesService.active.current;
  $scope.fundraisingPageHasCommitment = !!$scope.fundraisingPage.commitment || !!$scope.fundraisingPage.commitment_deadline;
  $scope.isPageOwner = $scope.fundraisingPage.member_id == scMembersService.active.current.id;

  $scope.campaignEndDate = scCampaignsService.active.current.ended_at;
  $scope.campaign = scCampaignsService.active;

  // if the designation is not set for the fundraising page, we need the model to update
  // to the current campaign default designation, although this causes issues because the
  // value will be saved at the page level so if the campaign changes their default
  // designation it will not be reflected by the fundraising page
  $scope.fundraisingPage.designation_id = $scope.fundraisingPage.designation_id || $scope.campaign.current.designation_id;

  $scope.designationOptions = {
    check: {
      enabled: false
    },
    containerClass: 'full',
    fieldName: 'designation',
    label: 'Program Designation',
    sublabel: 'New gifts to this fundraiser will be earmarked for this designation. Previous gifts will not be altered.',
    valueKey: 'id',
    labelKey: 'name'
  };

  $scope.$watch('fundraisingPage.raw_goal', function (newVal) {
    if ($scope.fundraisingPageHasCommitment) {
      $scope.fundraisingGoalError = newVal < $scope.fundraisingPage.commitment_value;
      $scope.supporterForm.goal.$setValidity('customError', !$scope.fundraisingGoalError);
    }
  });

  if ($scope.campaignEndDate && !$scope.fundraisingPage.ended_at) {
    if (moment($scope.campaignEndDate).isAfter(moment($scope.fundraisingPage.started_at))) {
      $scope.fundraisingPage.endDate = $scope.campaignEndDate || scTimeMachineService.justDate(scTimeMachineService.campaignTime(moment(new Date()).add(1, 'years')), {
        inclusive: true,
        campaignTime: true
      });
    }
  }

  // If campaign end date is not set you can change the fundraising page end date
  if (!$scope.campaignEndDate) {
    $scope.fundraisingPage.endDate = scTimeMachineService.campaignTime(moment($scope.fundraisingPage.end_date || $scope.fundraisingPage.ended_at)).startOf('day');
    $scope.fundraisingPage.endDate = scTimeMachineService.zoneSwap($scope.fundraisingPage.endDate);
  }

  $scope.formSubmitting = false;
  $scope.froalaOptions = scFroalaOptions({
    type: 'bare-headline-max-length'
  }, {
    editorClass: 'dashboard-details bordered'
  });

  $scope.fundraisingPage.startDate = new Date();

  $scope.disableCurrencyDropdown = function () {
    return scFundraisingPagesService.active.current.transactions_count > 0 || scFundraisingPagesService.active.current.soft_credits_count > 0 || scFundraisingPagesService.active.current.percent_to_goal > 0;
  };

  $scope.fundraisingPage.logo = $scope.fundraisingPage.logo || $scope.block.current.defaultFundraiserLogo || null;

  $scope.subscribeOptions = {
    comments: {
      key: 'page.comment',
      subscribe: true
    },
    donations: {
      key: 'page.donation',
      subscribe: true
    },
    encouragement: {
      key: 'page.series',
      subscribe: true
    }
  };

  scDomainsService.getSlug('fundraising-pages', $scope.fundraisingPage.id).then(function (response) {
    if (response.data.total > 0) {
      $scope.fundraisingPage.domainSlug = response.data.data[0].value;
      oldSlugObj = response.data.data[0];
      hadSlug = true;
    } else {
      $scope.fundraisingPage.domainSlug = '';
      oldSlugObj = null;
      hadSlug = false;
    }
  });

  if ($scope.isPageOwner) {
    $http.get('/frs-api/campaigns/' + scFundraisingPagesService.active.current.campaign_id + '/notification-preferences').then(function (response) {
      preferences = response.data;

      _.forEach($scope.subscribeOptions, function (opt) {
        if (preferences.unsubscribe_tags.indexOf(opt.key) > -1) {
          opt.subscribe = false;
        }
      });
    }).catch(function (error) {
      $log.error(error);
      scBanner('There was an error loading your notification preferences.');
    });
  }

  $scope.removeButtonAction = function () {
    $scope.fundraisingPage.logo = $scope.block.current.defaultFundraiserLogo || null;
    $scope.fundraisingPage.logo_id = $scope.fundraisingPage.logo ? $scope.fundraisingPage.logo.id : null;
  };

  $scope.resetHeadlineText = $sce.trustAsHtml('Reset campaign headline');

  $scope.resetCampaignHeadline = function () {
    $scope.fundraisingPage.intro_text = scCampaignsService.active.current.default_page_appeal || '';
  };

  $scope.formSubmitText = function () {
    return 'Save Changes';
  };

  $scope.headerTitle = function () {
    if ($scope.pageType === 'fundraiser') {
      return 'Edit ' + ($scope.fundraisingPage.is_tribute ? 'page' : 'your') + ' details';
    } else {
      return 'Edit team details';
    }
  };

  $scope.$watch('fundraisingPage.logo', function (value) {
    if (!value) {
      if ($scope.block.current.defaultFundraiserLogo) {
        $scope.fundraisingPage.logo = $scope.block.current.defaultFundraiserLogo;
        $scope.fundraisingPage.logo_id = $scope.block.current.defaultFundraiserLogo.asset.assetId;
      } else {
        $scope.fundraisingPage.logo = null;
        $scope.fundraisingPage.fundraiser_profile_image = null;
      }
      $scope.hideRemoveButton = true;
    } else if (scImg.isInstance(value)) {
      $scope.fundraisingPage.logo_id = value.asset.assetId;
      $scope.hideRemoveButton = $scope.fundraisingPage.logo.is($scope.block.current.defaultFundraiserLogo);
      $scope.fundraisingPage.fundraiser_profile_image = value.asset.src;
    }
  });

  $scope.submit = function () {
    var page = scFundraisingPagesService.active;
    $scope.formSubmitting = true;

    if ($scope.fundraisingPage.endDate) {
      $scope.fundraisingPage.ended_at = scTimeMachineService.justDate($scope.fundraisingPage.endDate, {
        inclusive: false,
        campaignTime: true,
        returnTimestampAsObject: true
      }).set({ hour: 23, minute: 59, second: 59 }).format();
    }

    if (!$scope.fundraisingPage.started_at) {
      $scope.fundraisingPage.started_at = $scope.fundraisingPage.created_at;
    }

    if (!oldSlugObj || oldSlugObj.value != $scope.fundraisingPage.domainSlug) {
      scDomainsService.saveChanges(oldSlugObj, $scope.fundraisingPage.domainSlug, hadSlug, 'fundraising-pages', $scope.fundraisingPage.id).then(function (response) {
        oldSlugObj = response.data;
      });
    }

    var notificationsUpdate = void 0;
    if ($scope.isPageOwner) {
      var unsubscribeTags = _(preferences.unsubscribe_tags);
      var subscribed = [];
      var unsubscribed = [];
      _.forEach($scope.subscribeOptions, function (opt) {
        if (opt.subscribe) {
          subscribed.push(opt.key);
        } else {
          unsubscribed.push(opt.key);
        }
      });

      unsubscribeTags = unsubscribeTags.difference(subscribed).union(unsubscribed).value();
      preferences.unsubscribe_tags = unsubscribeTags;

      notificationsUpdate = $http.post('/frs-api/campaigns/' + page.current.campaign_id + '/notification-preferences', preferences);
    } else {
      notificationsUpdate = $q.resolve();
    }

    if (page.current.is_tribute && !page.current.raw_goal) {
      page.current.raw_goal = 0;
    }

    var fundraisingPageUpdate = page.update();

    $q.all([fundraisingPageUpdate, notificationsUpdate]).then(function () {
      return page.refreshData();
    }).then(function () {
      scBanner('Your page has been edited!', 'success');
      scAppealSetService.rescrape('fundraiser');
    }).catch(function (error) {
      $log.error(error);
      scBanner('An error occured while updating your details. Please try again.', 'error');
    }).finally(function () {
      $scope.formSubmitting = false;
    });
  };

  $scope.deletePage = function () {
    var confirmDelete = scFlowModalService.open({
      templateUrl: 'frs/shared/supporter/pages/details/delete-modal',
      context: $scope,
      maxWidth: 520
    }, {
      animate: true
    });

    confirmDelete.then(function () {
      var pageId = scFundraisingPagesService.active.current.id;

      scFundraisingPagesService.active.delete().then(function () {
        scBanner('Your page has been deleted. Redirecting...', 'success');

        delete scFundraisingPagesService.userFundraisers[pageId];
        delete scFundraisingPagesService.fundraisers[pageId];

        $timeout(function () {
          var landingUrl = scPagesService.getUrl({
            pageType: 'landing',
            status: 'active',
            includeBase: false
          }).url;

          $location.path(landingUrl);
        }, 3000);
      }, function (error) {
        $log.error(error);
      });
    });
  };

  scPagesService.decorate($scope, 'supporter', 'details-fundraising-page');
}

angular.module('classy.frs').controller('fundraisingPageDetailsCtrl', FundraisingPageDetailsCtrl);
})();