(function(){
'use strict';

scSupporterOverviewCtrl.$inject = ["$q", "$log", "$scope", "$state", "$http", "$rootScope", "scCardsService", "scCampaignsService", "scFundraisingPagesService", "scFundraisingTeamsService", "scFundraisingTeamModel", "scFlowModalService", "scCurrencyService", "fundraiseSwitchFlow", "SupporterOverviewDataFactory"];
var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function scSupporterOverviewCtrl($q, $log, $scope, $state, $http, $rootScope, scCardsService, scCampaignsService, scFundraisingPagesService, scFundraisingTeamsService, scFundraisingTeamModel, scFlowModalService, scCurrencyService, fundraiseSwitchFlow, SupporterOverviewDataFactory) {
  $scope.teams = scFundraisingTeamsService.teams;
  $scope.campaign = scCampaignsService.active.current;
  $scope.averageDonation = 0; // set a default value before data is hydrated
  $scope.fundaraiserId = _.get(scFundraisingPagesService, 'active.current.id', null);

  if ($state.current.name.indexOf('fundraiser') > -1) {
    $scope.pageType = 'fundraiser';
    $scope.currency = scFundraisingPagesService.active.current.raw_currency_code;
    $scope.pagePhoto = scFundraisingPagesService.getPagePhoto(scFundraisingPagesService.active.current);
  } else {
    $scope.pageType = 'team';
    $scope.currency = scFundraisingTeamsService.active.current.raw_currency_code;
    $scope.teamPhoto = scFundraisingTeamsService.getTeamPhoto(scFundraisingTeamsService.active.current);
  }

  if (!$scope.campaign.allow_team_fundraising) {
    $http({
      url: '/frs-api/campaign/' + $scope.campaign.id + '/fundraising-teams',
      method: 'GET'
    }).then(function (result) {
      $scope.teams = result.data.data;
    });
  }

  $scope.fundraiseSwitch = function () {
    fundraiseSwitchFlow({
      fundraiser: scFundraisingPagesService.active.current.id,
      disableIndividual: true
    });
  };

  /**
   * Cards
   */
  function currentCardFlow() {
    var currentFlow = 'fundraisingPageSetup';

    if ($state.current.name.indexOf('fundraiser') > -1) {
      currentFlow = 'fundraisingPageSetup';
    } else {
      currentFlow = 'teamPageSetup';
    }

    return currentFlow;
  }

  var cardFlow = scCardsService.cardFlows[currentCardFlow()];

  cardFlow.makeApiCalls();

  $scope.cardFlow = cardFlow.cards;

  $scope.visibleCards = function () {
    return _.filter(cardFlow.cards, function (c) {
      return !c.isSkipped && !c.isCompleted();
    });
  };

  $scope.setFirstVisibleCard = function () {
    if ($scope.visibleCards()[0]) $scope.visibleCards()[0].isFirstVisibleCard = true;
  };
  $scope.setFirstVisibleCard();

  $scope.showVisibleCards = function () {
    if ($scope.visibleCards().length) {
      var visibleCardId = document.getElementById($scope.visibleCards()[0].id);
      if (visibleCardId !== null) {
        var visibleCardButton = visibleCardId.getElementsByClassName('supporter-dashboard_overview-card-button');
        angular.element(visibleCardButton).attr('tabindex', 0);
        var visibleCardHeadline = visibleCardId.getElementsByClassName('supporter-dashboard_overview-card-title');
        angular.element(visibleCardHeadline).attr('aria-hidden', false);
      }
    }
  };

  $scope.skipCard = function (card) {
    card.skip();
    $scope.showVisibleCards();
  };

  $scope.completeEmail = function (card) {
    card.skip();
    $scope.goTo('emails.template');
  };

  $scope.goToDonateLink = function (amount) {
    scCurrencyService.convertValue(amount, $scope.currency, scCurrencyService.getDefaultCurrency()).then(function (convertedAmount) {
      if ($state.current.name.indexOf('fundraiser') > -1) {
        document.location.href = '/give/f' + scFundraisingPagesService.active.current.id + '/#!/donation/checkout?amount=' + convertedAmount;
      } else {
        document.location.href = '/give/t' + scFundraisingTeamsService.active.current.id + '/#!/donation/checkout?amount=' + convertedAmount;
      }
    });
  };

  $scope.resetCards = function () {
    _.forEach(cardFlow.cards, function (value) {
      value.isSkipped = false;
    });

    cardFlow.reset();
    $scope.setFirstVisibleCard();
  };

  $scope.skipCards = function () {
    _.forEach($scope.cardFlow, function (card) {
      card.skip();
    });
  };

  $scope.showEmptyTeam = function () {
    return !$scope.team && $scope.campaign.team_membership_policy === 'optional' && ($scope.teams.length || $scope.campaign.allow_team_fundraising) && $scope.campaign.allow_fundraising_pages;
  };

  /**
   * Set current tab for overview graph
   * @param  {string} tab A string designating the selected tab
   */
  $scope.setCurrentTab = function (tab) {
    $scope.currentTab = tab;
  };

  $scope.getOverviewTab = function () {
    return 'frs/shared/supporter/pages/overview/tabs/' + $scope.currentTab;
  };

  /**
   * Set the initial tab for our graph
   */
  function setInitialTab() {
    if ($state.current.name.indexOf('fundraiser') > -1) {
      $scope.setCurrentTab('fundraiser');
    } else if ($state.current.name.indexOf('team') > -1) {
      $scope.setCurrentTab('team');
    }
    $scope.resetCards();
  }

  /**
   * Set $scope.team if we're on a team dashboard
   */
  function setTeamScope() {
    if ($state.current.name.indexOf('team') > -1) {
      $scope.team = scFundraisingTeamsService.active;
    }
  }

  /**
   * Find the associated team if we're on a fundraising page
   * dashboard.
   */
  function getAssociatedTeam() {
    if ($scope.pageType === 'fundraiser' && !!scFundraisingPagesService.active.current.fundraising_team_id && scFundraisingPagesService.active.current.fundraising_team_id) {
      $http({
        method: 'GET',
        url: '/frs-api/fundraising-teams/' + scFundraisingPagesService.active.current.fundraising_team_id
      }).then(function (response) {
        $scope.team = new scFundraisingTeamModel(response.data);
        $scope.teamPhoto = scFundraisingTeamsService.getTeamPhoto($scope.team.current);
      }).catch($log.error.bind($log));
    } else {
      $scope.team = null;
      $log.log('No associated team.');
    }
  }

  /**
   * Get fundraising commitment agreement flag
   * if agreed_at flag is null claim model gets open
   */
  function getCommitmentAgreementFlag() {
    $http({
      method: 'GET',
      url: '/frs-api/fundraising-pages/' + $scope.fundaraiserId,
      params: {
        withCommitment: true
      }
    }).then(function (response) {
      var commitment = response.data.commitment;


      if (commitment && commitment.agreed_at === null) {
        scFlowModalService.open({
          template: '<fundraising-claim-modal></fundraising-claim-modal>',
          maxWidth: 800,
          context: $scope
        }, {
          closable: false,
          closeOnClickOut: false
        });
      }
    }).catch($log.error.bind($log));
  }

  /**
   * Find entity type and ID the graph should be associated with
   */
  $scope.getGraphEntity = function () {
    var graphEntity = {};

    if ($scope.currentTab == 'fundraiser') {
      graphEntity.type = 'fundraising-pages';
      graphEntity.id = scFundraisingPagesService.active.current.id;
      graphEntity.object = scFundraisingPagesService.active.current;
    } else if ($scope.currentTab == 'team') {
      graphEntity.type = 'fundraising-teams';
      graphEntity.id = scFundraisingTeamsService.active.current.id;
      graphEntity.object = scFundraisingTeamsService.active.current;
    } else if ($scope.currentTab == 'empty-team') {
      graphEntity.object = {};
    } else {
      graphEntity.type = 'campaigns';
      graphEntity.id = scCampaignsService.active.current.id;
      graphEntity.object = scCampaignsService.active.current;
    }

    return graphEntity;
  };

  var convertToGraphCurrency = function convertToGraphCurrency(amt, currency) {
    return scCurrencyService.convertValue(amt, $scope.campaign.normalized_currency_code, currency);
  };

  // Get the graph stats from the backend
  // exposed for testing purposes
  $scope.fetchOverviewMetrics = function (_ref, currency) {
    var id = _ref.id,
        type = _ref.type,
        entity = _ref.object;

    if (!id) {
      return $q.reject();
    }

    return $http({
      method: 'GET',
      url: '/frs-api/' + type + '/' + id + '/overview',
      params: {
        // TODO: Add interval toggles
        interval: 'daily'
      }
    }).then(function (response) {
      return $q.all(response.data.metrics.map(function (metric) {
        return (
          // All of the numbers which come back from this endpoint are given in terms of the
          // campaign currency. Rather than worrying about converting them later in the flow,
          // lets convert them all to the team currency now, so that we don't have to worry about
          // currency after this.
          //
          // While this *looks* like it's going to do a lot of async requests, it isn't. We'll be
          // converting every number from one currency into another currency. scCurrencyService
          // caches the conversion rates, so it'll make two requests (one for each currency) total.
          // All other conversions will resolve without having to wait for the conversion.
          // Technically we could grab the conversion rate and do it ourselves to remove the
          // extra async-looking code paths here, but since scCurrencyService is very well tested,
          // we'll leave this here and rely on it to handle everything correctly
          $q.all({
            donations_amount: convertToGraphCurrency(metric.donations_amount, currency),
            fees_amount: convertToGraphCurrency(metric.fees_amount, currency),
            gross_amount: convertToGraphCurrency(metric.gross_amount, currency),
            net_amount: convertToGraphCurrency(metric.net_amount, currency),
            soft_credits_amount: convertToGraphCurrency(metric.soft_credits_amount, currency),
            // progress_bar_amount is already in the team or page raw currency and doesn't need to be converted
            progress_bar_amount: metric.progress_bar_amount
          })
          // the result of the $q.all here will be an object with the above properties, whose names
          // match the existing fields in the metric object. When the requests resolve, we then
          // overwrite the original values of the metric object with the converted values from
          // the resolved requests, creating an object with the same fields as the original one
          // but whose values have been converted from the campaign's currency to the team's
          // currency
          .then(function (converted) {
            return Object.assign({}, metric, converted);
          })
        );
      }));
    })
    // at this point, all numbers are now in terms of the team's currency
    .then(function (metrics) {
      // separate out the aggregate data item from the rest of the items
      // the endpoint provides an 'aggregate' function which should be filtered out
      var _$groupBy = _.groupBy(metrics, function (_ref2) {
        var startDate = _ref2.start_date;
        return !!startDate;
      }),
          _$groupBy$true = _$groupBy.true,
          nonAggregateData = _$groupBy$true === undefined ? [] : _$groupBy$true,
          _$groupBy$false = _$groupBy.false;

      _$groupBy$false = _$groupBy$false === undefined ? [] : _$groupBy$false;

      var _$groupBy$false2 = _slicedToArray(_$groupBy$false, 1),
          _$groupBy$false2$ = _$groupBy$false2[0],
          aggregateData = _$groupBy$false2$ === undefined ? {} : _$groupBy$false2$;

      return {
        transactionsCount: parseInt(aggregateData.transactions_count, 10) || 0,
        metrics: nonAggregateData,
        total: SupporterOverviewDataFactory.getMetricData(aggregateData),
        goal: entity.raw_goal
      };
    });
  };

  // update graph stats in the UI
  // exposed for testing purposes
  $scope.setOverviewMetrics = function (entity) {
    var currency = entity.object.goal || entity.object.goal_normalized || entity.object.progress_bar_amount ? scCurrencyService.getEntityCurrency(entity.object) : _.get(entity.object, 'normalized_currency_code');

    $scope.fetchOverviewMetrics(entity, currency).then(function (_ref3) {
      var transactionsCount = _ref3.transactionsCount,
          metrics = _ref3.metrics,
          total = _ref3.total,
          goal = _ref3.goal;

      $scope.currency_code = currency;
      $scope.raisedAmount = total;
      $scope.transactionsCount = transactionsCount;
      // don't divide by zero if there are no donors
      $scope.averageDonation = $scope.transactionsCount ? $scope.raisedAmount / $scope.transactionsCount : 0;

      $scope.metrics = metrics;
      $scope.chartColor = $scope.theme.styles.primaryColor;
      $scope.goal = goal;
      $scope.amountRemaining = goal - total;
      $scope.goalReached = $scope.amountRemaining <= 0;
      // if we've reached our goal or there are no days left, skip setting this value and just
      // display either a congratulations message or a message about how much more you need to
      // raise

      var endDate = entity.object.ended_at || scCampaignsService.active.current.ended_at || false;

      // Note: due to the way that moment calculates the differences in days,
      // we need to use the startOf method to reset each date back to midnight before
      // doing comparison. Otherwise, we can end up with situations where the difference is
      // off by one.

      var daysRemaining = endDate ? moment(endDate).startOf('day').diff(moment([]).startOf('day'), 'days') : false;

      $scope.raisePerDay = !$scope.goalReached && daysRemaining && daysRemaining > 0 ? $scope.amountRemaining / daysRemaining : 0;
    }).catch(function () {}); // if we failed, just don't write to the graph
  };

  $scope.leaveTeam = function () {
    scFlowModalService.open({
      templateUrl: 'frs/shared/supporter/pages/overview/leave-team-modal',
      context: $scope,
      maxWidth: 520
    }, { animate: true }).then(function () {
      return scFundraisingPagesService.active.leaveTeam();
    }).then(function () {
      $rootScope.SC.status.banner = {
        type: 'success',
        msg: 'You have left the team.'
      };

      $scope.team = null;
      $scope.currentTab = 'empty-team';
    }).catch($log.error.bind($log));
  };

  // These method calls moved to the end to ensure that all methods are defined before being called

  /**
   * Set initial tab
   */
  setInitialTab();
  getAssociatedTeam();
  setTeamScope();
  getCommitmentAgreementFlag();

  // Note: for testing purposes, we need a way to prevent this request from firing in order to
  // properly test these methods.
  if (!$scope.skipInitialOverviewFetch) {
    $scope.setOverviewMetrics($scope.getGraphEntity());
  }

  /**
   * Update the graph with new data whenever the
   * current tab changes.
   */
  $scope.$watch('currentTab', function (newVal, oldVal) {
    if (newVal !== oldVal) {
      $scope.setOverviewMetrics($scope.getGraphEntity());
    }
  });
}

angular.module('classy.frs').controller('scSupporterOverviewCtrl', scSupporterOverviewCtrl);
})();