(function(){
'use strict';

dashboardUpdatesCreateCtrl.$inject = ["$scope", "$state", "$timeout"];
function dashboardUpdatesCreateCtrl($scope, $state, $timeout) {
  $scope.post = {
    title: '',
    body: '',
    broadcast: true,
    status: 'published'
  };

  $scope.loading = false;
  $scope.postAttempted = false;
  $scope.notification = {
    type: '',
    text: ''
  };

  $scope.iconColor = $scope.theme.styles.primaryColor;

  $scope.toggleBroadcast = function () {
    $scope.post.broadcast = !$scope.post.broadcast;

    if (!$scope.post.broadcast) {
      $scope.iconColor = '#d3d3d3';
    } else {
      $scope.iconColor = $scope.theme.styles.primaryColor;
    }
  };

  /* Create a new post */
  $scope.submit = function () {
    $scope.loading = true;
    $scope.campaign.createPost($scope.post).then(function (response) {
      $scope.loading = false;
      $scope.postAttempted = true;
      $scope.notification = {
        type: 'success',
        text: 'Your update has been published!'
      };

      // redirect to post preview upon successful publish
      $timeout(function () {
        $state.go($scope.nextPage + '.preview', { postId: response.data.id });
      }, 700);
    }, function () {
      $scope.loading = false;
      $scope.postAttempted = true;
      $scope.notification = {
        type: 'error',
        text: 'There was an error publishing your update. Please try again.'
      };

      $timeout(function () {
        $scope.postAttempted = false;
      }, 2000);
    });
  };

  $scope.delete = function () {
    $scope.goTo('updates.manage');
  };

  $scope.$watch('post', function (newVal) {
    if (newVal.title.length > 0 || newVal.body.length > 0) {
      $scope.displayDelete = true;
    } else {
      $scope.displayDelete = false;
    }
  }, true);
}

angular.module('classy.frs').controller('dashboardUpdatesCreateCtrl', dashboardUpdatesCreateCtrl);
})();