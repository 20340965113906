(function(){
'use strict';

/**
 * @kind run
 * @name page-data
 *
 * @description
 * A run block to instantiate the fieldOptions for the crowdfunding campaign setup.
 */

supporterFundraisingTeamFormData.$inject = ["scPagesService"];
function supporterFundraisingTeamFormData(scPagesService) {
  var fieldOptions = {
    photo: {
      model: 'team.current.logo', // MongoDB Team Block
      previewWidth: 195,
      previewHeight: 195,
      width: 300,
      height: 300,
      label: 'Team Picture',
      buttonText: 'Upload',
      format_text: 'For best quality, use a 300 x 300 px image',
      labelInline: true,
      buttonStyle: 'submitButtonStyle()',
      hasRemoveButton: '!hideRemoveButton',
      removeButtonStyle: 'removeButtonStyle()',
      removeButtonText: 'Remove',
      removeButtonAction: 'removeButtonAction()',
      nullImage: "'/static/global/images/team_default_image.png'"
    }
  };

  var pageData = {
    scope: {
      fieldOptions: fieldOptions
    },
    init: _.noop
  };

  scPagesService.add('supporter', 'details-fundraising-team', pageData);
}

angular.module('classy.frs').run(supporterFundraisingTeamFormData);
})();