(function(){
'use strict';

/**
 * Controller for listing donations of a team or page in the
 * fundraising dashboard. Allows for acknowledgements donations
 * and allocating funds if it is a team.
 * @kind Controller
 */

scSupporterDonationsCtrl.$inject = ["$log", "$scope", "$timeout", "$state", "scFundraisingPagesService", "scFundraisingTeamsService", "scMembersService", "DonationsCollection"];
function scSupporterDonationsCtrl($log, $scope, $timeout, $state, scFundraisingPagesService, scFundraisingTeamsService, scMembersService, DonationsCollection) {
  var model = void 0;
  if ($state.includes('frs.team')) {
    model = _.get(scFundraisingTeamsService.active, 'current', null);
  } else {
    model = _.get(scFundraisingPagesService.active, 'current', null);
  }

  if (!model) {
    $log.warn('Fundraiser Dashboard Donation Controller: Neither a team nor a page is defined.');
  }

  // Scope Properties
  $scope.notification = {};
  $scope.isTeam = !!$state.includes('frs.team');
  $scope.donations = [];
  $scope.allocation = {};
  $scope.me = scMembersService.active.current;
  $scope.sort = 'desc';

  // Scope Methods
  $scope.loadMoreDonations = loadMoreDonations;
  $scope.resort = resort;
  $scope.getMemberFirstInitial = scMembersService.getMemberFirstInitial;

  var donationsCollection = new DonationsCollection(model, $scope.me, $scope.isTeam);
  loadMoreDonations();

  if ($scope.isTeam) {
    $scope.allocation.amount = model.net_credit_balance;
  }

  // eslint-disable-next-line no-unused-vars
  $scope.$on('sc-donor-thanked', function (event, args) {
    $scope.notification = args.notification;
    $scope.showVerification = true;

    $timeout(function () {
      $scope.showVerification = false;
    }, 3000);
  });

  $scope.searchUpdates = {
    filter: ''
  };

  $scope.donateLink = function () {
    if ($state.current.name.indexOf('fundraiser') > -1) {
      return '/give/f' + scFundraisingPagesService.active.current.id + '/#!/donation/checkout';
    }
    return '/give/t' + scFundraisingTeamsService.active.current.id + '/#!/donation/checkout';
  };

  function loadMoreDonations() {
    $scope.loading = true;
    donationsCollection.get($scope.sort).then(function (donations) {
      $scope.loading = false;
      $scope.donations = $scope.donations.concat(donations.data.data);
      $scope.displayLoadMore = donations.data.data.length == 10;
    });
  }

  function resort() {
    $scope.sort = $scope.sort == 'asc' ? 'desc' : 'asc';
    $scope.donations = [];
    donationsCollection.resetPage();
    loadMoreDonations();
  }
}

angular.module('classy.frs').controller('scSupporterDonationsCtrl', scSupporterDonationsCtrl);
})();