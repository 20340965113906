(function(){
'use strict';

angular.module('classy.frs').component('fundraisingClaimModal', {
  templateUrl: 'frs/shared/supporter/pages/fundraisingclaim/template',

  controller: ["$log", "$http", "$scope", "$rootScope", "US_STATE_LIST", "CA_PROVINCE_LIST", "scBanner", "scFlowModalService", "scCampaignsService", "scFundraisingPagesService", "scFundraisingCommitmentService", "scOrganizationsService", "countryBlockService", "cpConstants", function controller($log, $http, $scope, $rootScope, US_STATE_LIST, CA_PROVINCE_LIST, scBanner, scFlowModalService, scCampaignsService, scFundraisingPagesService, scFundraisingCommitmentService, scOrganizationsService, countryBlockService, cpConstants) {
    var _this2 = this;

    this.paymentModalInitialize = false;

    var PAYMENT_METHODS = cpConstants.PAYMENT_METHODS;


    $scope.MODEL = {};

    this.$onInit = function () {
      var _this = this;

      this.campaign = scCampaignsService.active.current;
      this.fundaraiserId = _.get(scFundraisingPagesService, 'active.current.id', null);
      this.dateOfEvent = this.campaign.ended_at ? moment(this.campaign.started_at).format('LL') + ' - ' + moment(this.campaign.ended_at).format('LL') : moment(this.campaign.started_at).format('LL');

      this.disabledPaymentMethods = ['ACH', 'PayPal']; // ACH, PAYPAL option not supported for commitment
      this.api = {};
      this.usStateList = _.cloneDeep(US_STATE_LIST);
      this.caProvinceList = _.cloneDeep(CA_PROVINCE_LIST);
      countryBlockService.getCountryBlockedList().then(function (response) {
        _this.countryList = response;
      });

      /** Set default country as US */
      this.billing_address = {
        country: 'US'
      };

      $http({
        method: 'GET',
        url: '/frs-api/fundraising-pages/' + this.fundaraiserId,
        params: {
          with: 'supporter,commitment,registration'
        }
      }).then(function (response) {
        var fundraisingPageData = response.data;
        var registration = fundraisingPageData.registration,
            commitment = fundraisingPageData.commitment,
            supporter = fundraisingPageData.supporter;


        _this.fundraiserCommitmentValue = commitment.value;
        _this.fundraiserCommitmentDeadline = moment(commitment.deadline).format('LL');
        _this.commitmentInfoDate = _this.getCommitmentInfoDate(commitment.deadline) || '';

        _.extend($scope.MODEL, { supporter: supporter }, { registration: registration }, { billing_address: _this.billing_address });

        _.merge($scope.MODEL, {
          commitmentWaiver: false,
          paymentModel: {
            currency: fundraisingPageData.raw_currency_code
          },
          application_id: scOrganizationsService.getTag('classyPayApplicationId')
        });

        _this.paymentModalInitialize = true;
      }).catch($log.error.bind($log));
    };

    /**
     * Get date formate to display in commitment Waiver
     * */
    this.getCommitmentInfoDate = function (date) {
      return moment(date).format('MMMM Do YYYY, h:mm a') + ' ' + moment().tz(_this2.campaign.timezone_identifier).format('z');
    };

    /**
     * To convert expirationYear to 4 digit ie 2023
     * */
    this.getFullYear = function (twoOrFourDigitYear) {
      if (twoOrFourDigitYear.toString().length === 2) {
        return parseInt('' + new Date().getFullYear().toString().substr(0, 2) + twoOrFourDigitYear + '}', 10);
      }
      return parseInt(twoOrFourDigitYear, 10);
    };

    /**
     * getPayloadData:Build payloadData
     */
    var getPayloadData = function getPayloadData(paymentDetails) {
      var _$scope$MODEL = $scope.MODEL,
          billing_address = _$scope$MODEL.billing_address,
          paymentModel = _$scope$MODEL.paymentModel,
          supporter = _$scope$MODEL.supporter,
          registration = _$scope$MODEL.registration;
      var gateway = paymentModel.gateway,
          cc = paymentModel.cc;

      var personalDetails = _.omit(supporter, 'email_address');

      var paymentType = gateway.name === PAYMENT_METHODS.STRIPE.toUpperCase();

      var requestPayload = paymentType ? {
        source_info: {
          paymentMethod: 'Stripe',
          source: paymentDetails.id
        },
        agreed_at: moment.utc(new Date()).format()
      } : {
        card_info: {
          expirationMonth: cc.expirationMonth,
          expirationYear: _this2.getFullYear(cc.expirationYear),
          paymentMethod: 'CC',
          token: cc.token,
          cvv: cc.securityCode
        },
        agreed_at: moment.utc(new Date()).format()
      };

      return _.extend(requestPayload, { billing_address: billing_address }, { supporter: personalDetails }, { registration: registration });
    };

    /**
     * Submit call: Calls setupPayment() to fetch payment details and updates
     * supporter, registeration and commitment Table
     */
    $scope.submitForm = function () {
      var setupPayment = _this2.api.setModelPayment;

      return setupPayment().then(function (response) {
        $scope.submitButtonDisabled = true;
        /**
         * Build Payment Details
         */
        var paymentDetails = getPayloadData(response);
        return scFundraisingCommitmentService.putFundraisingCommitment(paymentDetails);
      }).then(function () {
        scBanner('Nice work! Your changes have been saved.', 'success');
        $scope.submitButtonDisabled = false;
        scFlowModalService.close();
      }).catch(function (err) {
        if (err.data) {
          $scope.submitButtonDisabled = false;
        }
        $rootScope.$broadcast('tokenizer', 'reset');
      });
    };
  }]
});
})();