(function(){
'use strict';

angular.module('classy.frs').controller('frsCtrl', ["$scope", "$state", "$stateParams", "$window", "$log", "$location", "cpIntelligentAsk", "EmbeddedGivingAnalytics", "EmbeddedGivingMessenger", "EmbeddedGivingUtil", "scAnalyticsHelper", "scAuth", "scFundraisingPagesService", "scFundraisingTeamsService", "scThemesService", "scCampaignsService", "scMembersService", "scOrganizationsService", "scInlineStyles", "scAnalytics", "scAnalyticsServiceSettings", function ($scope, $state, $stateParams, $window, $log, $location, cpIntelligentAsk, EmbeddedGivingAnalytics, EmbeddedGivingMessenger, EmbeddedGivingUtil, scAnalyticsHelper, scAuth, scFundraisingPagesService, scFundraisingTeamsService, scThemesService, scCampaignsService, scMembersService, scOrganizationsService, scInlineStyles, scAnalytics, scAnalyticsServiceSettings) {
  var campaign = scCampaignsService.active;

  // Donation and Thank you pages cannot read $state.params.preview
  $scope.previewMode = !!($state.params.preview || SC.preview);

  /* Services to $scope */
  $scope.scInlineStyles = scInlineStyles;
  $scope.$stateParams = $stateParams;

  $scope.members = scMembersService;
  $scope.you = scMembersService.you;

  $scope.$watch(function () {
    return scMembersService.you;
  }, function () {
    $scope.you = scMembersService.you;
  });

  $scope.organization = scOrganizationsService.active;
  $scope.theme = scThemesService.active.current;
  $scope.campaign = scCampaignsService.active.current;
  $scope.fundraiser = scFundraisingPagesService.active;

  // if EG campaign, wire up EG analytics
  if (scCampaignsService.active.hasEmbeddedGiving()) {
    EmbeddedGivingAnalytics.init(scCampaignsService.active);
  }
  /* scAnalytics Setup */
  scAnalytics.registerSuperProperties();
  scAnalyticsServiceSettings.init(SC.analyticsServiceSettings);
  $scope.onDashboard = function () {
    return $state.current.name.indexOf('dashboard') > -1;
  };
  scAnalyticsHelper.setup($scope);
  scAnalytics.registerPageViewCallback(function () {
    // We want to remove the is_new query param after the page view has been
    // tracked so that we don't get erroneous page view counts in GA
    if ($location.search().is_new) {
      $state.go($state.$current.name, { is_new: null }, { notify: false });
    }
  });

  // If we are not operating within an iframe, conditionally initialize these services:
  // -> Optimizely, Heap, and Intelligent Ask Analytics (IAA)
  // NOTE: initialization of these services is deferred until the EG SDK "open" message is received (see EmbeddedGivingMessenger)
  if (campaign && !EmbeddedGivingUtil.isInIframe()) {
    // Initialize Heap analytics if there is a Heap ID present
    if (SC.heapId) {
      scAnalytics.initHeap();
    }

    // Initialize Optimizely if the organization is tagged for A/B testing
    if (scOrganizationsService.getTag('early-access:ab-testing', true)) {
      scAnalytics.initOptimizely();
    }

    // Initialize Intelligent Ask Analytics (IAA) if the campaign has it enabled.
    // We assume access is granted in this case.
    // Note: This check only applies to admin portals, not the public donor experience.
    if (campaign.hasIntelligentAsk()) {
      cpIntelligentAsk.init(campaign);
    }
  } else {
    EmbeddedGivingMessenger.postMessageIframeLoad();
  }

  // eslint-disable-next-line no-unused-vars
  $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState) {
    if (toState.name && fromState.name && (!(toState.name.indexOf(fromState.name) > -1 || fromState.name.indexOf(toState.name) > -1) || fromState.name == 'frs.team.index' && toState.name == 'frs.team.index')) {
      // don't scroll during sub view changes or if coming from an abstract state
      $window.scrollTo(0, 0);
    }

    /**
     * Check SSO status
     */
    scAuth.checkStatus().then(function () {
      $scope.$broadcast('memberChecked');
    }).catch(function () {});

    // Hide the block contents in bakcground while viewing fundraiser dashboard
    if ($scope.onDashboard()) {
      $scope.truncateForDashboard = { hide: true };
    } else {
      $scope.truncateForDashboard = { hide: false };
    }
  });

  $scope.memberPagesPromise = scMembersService.getAllMemberPages(scMembersService.you.current.id, scCampaignsService.active.current.id).then(function (resp) {
    _.forEach(resp.fundraisingPages, function (page) {
      scFundraisingPagesService.add(page);
      $scope.pages = scFundraisingPagesService;

      // add the team associated with the fundraising page
      if (page.fundraising_team_id) {
        scFundraisingTeamsService.fetch(page.fundraising_team_id);
      }
    });

    // getAllMemberPages only grabs teams that the member is a team lead for (aka created the team)
    _.forEach(resp.fundraisingTeams, function (team) {
      scFundraisingTeamsService.add(team);
      $scope.team = scFundraisingTeamsService;
    });
  });

  $scope.scAuth = scAuth;

  // Added for [FRS-7447]
  $log.log('Classy is looking to hire developers like you! To learn more visit: (https://www.classy.org/careers)');
}]);
})();