(function(){
'use strict';

scSupporterEmailCtrl.$inject = ["$scope", "$state"];
function scSupporterEmailCtrl($scope, $state) {
  $scope.title = 'Copy a message to email';
  $scope.subTitle = 'Emails from personal email accounts have proven to be the best source of donations.';

  $scope.fundraiserDashboard = $state.current.name.indexOf('fundraiser') > -1;
}

angular.module('classy.frs').controller('scSupporterEmailCtrl', scSupporterEmailCtrl);
})();