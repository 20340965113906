(function(){
'use strict';

frsCreateFundraisingPageCtrl.$inject = ["$scope", "$state", "createFundraisingPageFlow", "resolvedUserFundraisers", "scFundraisingTeamsService", "scOrganizationsService", "scCampaignsService", "scFlowModalService", "scThemesService", "scAnalytics", "scMembersService"];
angular.module('classy.frs').controller('frs-createFundraisingPageCtrl', frsCreateFundraisingPageCtrl);

function frsCreateFundraisingPageCtrl($scope, $state, createFundraisingPageFlow, resolvedUserFundraisers, scFundraisingTeamsService, scOrganizationsService, scCampaignsService, scFlowModalService, scThemesService, scAnalytics, scMembersService) {
  var optionsObj = {};
  /* ---------------------------------------------------------------------- *
   * Redirect if user has a fundraiser already
   * AND if multiple fundraising pages is turned off
   * ---------------------------------------------------------------------- */

  if (resolvedUserFundraisers && resolvedUserFundraisers.data.total) {
    // the regular P2P use case
    if (!scCampaignsService.active.current.allow_duplicate_fundraisers) {
      var activeFundraiser = _.findLast(resolvedUserFundraisers.data.data, { status: 'active' });

      if (activeFundraiser) {
        $state.go('frs.fundraiser.index', { fundraisingPageId: activeFundraiser.id });
        if (scFlowModalService.activeView) {
          scFlowModalService.close();
        }

        return;
      }
    } else {
      // if we are in a multiple fundraising pages P2P, send in a starting view and some
      // seed fundraising pages
      optionsObj.startingView = 'indiv.multiple-pages';
      optionsObj.fundraisingPages = resolvedUserFundraisers;
    }
  } else {
    // This event is called whenever the user is logged in and they don't have
    // an existing fundraising page OR duplicate fundraisers are enabled,
    // meaning this is the true start to the fundraiser create flow
    scAnalytics.eventBeacon({
      category: 'frs ' + scCampaignsService.active.current.type,
      action: 'click',
      label: 'Fundraise as Individual',
      logged_in: !!scMembersService.active.current.id || false
    });
  }

  /* ---------------------------------------------------------------------- *
   * Configure block rendering
   * ---------------------------------------------------------------------- */

  $scope.public = true;
  $scope.editable = false;
  $scope.demo = false;
  $scope.organization = scOrganizationsService.active.current;
  $scope.campaign = scCampaignsService.active.current;
  $scope.theme = scThemesService.active.current;
  $scope.page = $scope.theme.pages.fundraiser;
  $scope.team = _.get(scFundraisingTeamsService, 'active.current', null);

  /* ---------------------------------------------------------------------- *
   * Launch flow
   *
   * Returns the empty fundraiser model that will be used in both the flow
   * and for block rendering.
   * ---------------------------------------------------------------------- */

  $scope.fundraiser = createFundraisingPageFlow(optionsObj);
}
})();