(function(){
'use strict';

scCardsService.$inject = ["$http", "scCardModel", "scFundraisingPagesService", "scFundraisingTeamsService", "scCampaignsService", "scBanner", "CHANNEL"];
function scCardsService($http, scCardModel, scFundraisingPagesService, scFundraisingTeamsService, scCampaignsService, scBanner, CHANNEL) {
  var _this = this;

  var isFBPageCreated = void 0,
      isCampaignFBEnabled = void 0;

  this.cardFlows = {
    teamPageSetup: {
      reset: function reset() {
        window.localStorage.setItem('scCards_t' + scFundraisingTeamsService.active.current.id + '-setup', '[]');
      },


      makeApiCalls: function makeApiCalls() {
        /**
         * Updates
         */
        $http({
          method: 'GET',
          url: '/frs-api/fundraising-teams/' + scFundraisingTeamsService.active.current.id + '/posts' + '?per_page=2'
        }).then(function (response) {
          _this.updates = response.data.data;
        });

        /**
         * Donations
         */
        $http({
          method: 'GET',
          url: '/frs-api/fundraising-teams/' + scFundraisingTeamsService.active.current.id + '/feed-items' + ('?filter=linkable_type=donation&campaignId=' + scCampaignsService.active.current.id)
        }).then(function (response) {
          _this.donations = response.data.data;
        });
      },

      cards: [new scCardModel({
        id: 'add-photo',
        template: 'frs/shared/supporter/cards/templates/add-photo',
        isCompleted: function isCompleted() {
          return !!scFundraisingTeamsService.active.current.logo_id;
        },


        pageType: 'fundraisingTeam'
      }), new scCardModel({
        id: 'edit-headline',
        template: 'frs/shared/supporter/cards/templates/edit-headline',
        isCompleted: function isCompleted() {
          var descriptionText = _.get(scFundraisingTeamsService, 'active.current.description', '');
          var descriptionStripped = descriptionText.replace('<p>', '').replace('</p>', '');

          return scCampaignsService.active.current.default_team_appeal !== descriptionStripped;
        },


        pageType: 'fundraisingTeam'
      }), new scCardModel({
        id: 'write-update',
        template: 'frs/shared/supporter/cards/templates/write-update',
        isCompleted: function isCompleted() {
          return _this.updates ? _this.updates.length > 1 : false;
        },
        pageType: 'fundraisingTeam'
      }), new scCardModel({
        id: 'donate-to-campaign',
        template: 'frs/shared/supporter/cards/templates/donate-to-campaign',
        isCompleted: function isCompleted() {
          return _this.donations ? _this.donations.length > 0 : false;
        },
        pageType: 'fundraisingTeam'
      }), new scCardModel({
        id: 'ask-for-donations',
        template: 'frs/shared/supporter/cards/templates/ask-for-donations',
        isCompleted: function isCompleted() {
          return false;
        },


        pageType: 'fundraisingTeam'
      })]
    },
    fundraisingPageSetup: {
      reset: function reset() {
        window.localStorage.setItem('scCards_f' + scFundraisingPagesService.active.current.id + '-setup', '[]');
      },


      makeApiCalls: function makeApiCalls() {
        /**
         * Updates
         */
        $http({
          method: 'GET',
          url: '/frs-api/fundraising-pages/' + scFundraisingPagesService.active.current.id + '/posts' + '?per_page=2'
        }).then(function (response) {
          _this.updates = response.data.data;
        });

        /**
         * Donations
         */
        $http({
          method: 'GET',
          url: '/frs-api/fundraising-pages/' + scFundraisingPagesService.active.current.id + '/feed-items' + ('?filter=linkable_type=donation&campaignId=' + scCampaignsService.active.current.id)
        }).then(function (response) {
          _this.donations = response.data.data;
        });

        /**
         * GET API call for Facebook Fundraising Page
         */
        if (SC.organization.facebookChannel.status) {
          scFundraisingPagesService.active.getChannelFundraisingPage(CHANNEL.FB).then(function (response) {
            if (response && response.data[0]) isFBPageCreated = !!response.data[0].external_fundraising_page_id;else isFBPageCreated = false;
          });

          scCampaignsService.active.getCampaignChannel(CHANNEL.FB).then(function (resp) {
            isCampaignFBEnabled = _.get(resp, '[0].status', false);
          }).catch(function () {
            scBanner('There was an error fetching the campaign channel status.', 'error');
          });
        }
      },

      cards: [new scCardModel({
        id: 'add-photo',
        template: 'frs/shared/supporter/cards/templates/add-photo',
        isCompleted: function isCompleted() {
          return !!scFundraisingPagesService.active.current.logo_id;
        },


        pageType: 'fundraisingPage'
      }), new scCardModel({
        id: 'edit-headline',
        template: 'frs/shared/supporter/cards/templates/edit-headline',
        isCompleted: function isCompleted() {
          var introText = _.get(scFundraisingPagesService, 'active.current.intro_text', _.get(scFundraisingPagesService, 'active.current.intro', ''));
          // this is set to null instead of undefined so it won't trigger the default value by lodash, need to manually check here
          if (introText === null) {
            introText = '';
          }

          var introTextStripped = introText.replace('<p>', '').replace('</p>', '');

          return scCampaignsService.active.current.default_page_appeal !== introTextStripped;
        },


        pageType: 'fundraisingPage'
      }), new scCardModel({
        id: 'write-update',
        template: 'frs/shared/supporter/cards/templates/write-update',
        isCompleted: function isCompleted() {
          return _this.updates ? _this.updates.length > 1 : false;
        },
        pageType: 'fundraisingPage'
      }), new scCardModel({
        id: 'donate-to-campaign',
        template: 'frs/shared/supporter/cards/templates/donate-to-campaign',
        isCompleted: function isCompleted() {
          return _this.donations ? _this.donations.length > 0 : false;
        },
        pageType: 'fundraisingPage'
      }), new scCardModel({
        id: 'ask-for-donations',
        template: 'frs/shared/supporter/cards/templates/ask-for-donations',
        isCompleted: function isCompleted() {
          return false;
        },


        pageType: 'fundraisingPage'
      }), new scCardModel({
        id: 'create-facebook-fundraiser',
        template: 'frs/shared/supporter/cards/templates/create-facebook-fundraiser',
        isCompleted: function isCompleted() {
          return !isCampaignFBEnabled || isFBPageCreated || scFundraisingPagesService.active.current.is_tribute;
        },
        pageType: 'fundraisingPage'
      })]
    }
  };
}

angular.module('classy.frs').service('scCardsService', scCardsService);
})();