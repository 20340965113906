(function(){
'use strict';

/**
 * @kind service
 * @name scUpdatesService
 *
 * @description
 * Stores a post object
 */

scUpdatesService.$inject = ["$http", "$q"];
function scUpdatesService($http, $q) {
  this.get = function (id) {
    var deferred = $q.defer();
    $http.get('/frs-api/updates/' + id).then(function (response) {
      deferred.resolve(response.data);
    }, function (err) {
      deferred.reject(err);
    });
    return deferred.promise;
  };
}

angular.module('classy.frs').service('scUpdatesService', scUpdatesService);
})();