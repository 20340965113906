(function(){
'use strict';

scCardModel.$inject = ["scFundraisingPagesService", "scFundraisingTeamsService"];
function scCardModel(scFundraisingPagesService, scFundraisingTeamsService) {
  function Card(options) {
    this.id = options.id;
    this.template = options.template;
    this.isCompleted = options.isCompleted;
    this.pageType = options.pageType;

    if (this.pageType === 'fundraisingPage' && scFundraisingPagesService.active) {
      this.prefix = 'f';
      this.pageId = scFundraisingPagesService.active.current.id;
    } else if (this.pageType === 'fundraisingTeam' && scFundraisingTeamsService.active) {
      this.prefix = 't';
      this.pageId = scFundraisingTeamsService.active.current.id;
    }

    var cardStorage = JSON.parse(window.localStorage.getItem('scCards_' + this.prefix + this.pageId + '-setup')) || [];

    this.isSkipped = cardStorage.indexOf(this.id) > -1;
    this.isFirstVisibleCard = false;
  }

  Card.prototype.skip = function () {
    var cardStorage = JSON.parse(window.localStorage.getItem('scCards_' + this.prefix + this.pageId + '-setup')) || [];

    if (cardStorage.indexOf(this.id) <= -1) {
      cardStorage.push(this.id);
    }

    window.localStorage.setItem('scCards_' + this.prefix + this.pageId + '-setup', JSON.stringify(cardStorage));

    this.isSkipped = true;
  };

  return Card;
}

angular.module('classy.frs').factory('scCardModel', scCardModel);
})();