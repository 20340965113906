(function(){
'use strict';

adminNpoHeaderCtrl.$inject = ["$scope", "$state", "$stateParams", "scCampaignsService", "scOrganizationsService"];
function adminNpoHeaderCtrl($scope, $state, $stateParams, scCampaignsService, scOrganizationsService) {
  $scope.state = $state;
  $scope.$stateParams = $stateParams;
  $scope.campaign = scCampaignsService;
  $scope.organization = scOrganizationsService;
}

angular.module('classy.frs').controller('adminNpoHeaderCtrl', adminNpoHeaderCtrl);
})();