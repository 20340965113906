(function(){
'use strict';

frsDonationThankYouCtrl.$inject = ["$scope", "$rootScope", "resolvedTransactionSummary", "scFlowModalService", "scFundraisingPagesService", "scFundraisingTeamsService", "scTransientsService", "scCurrencyService"];
angular.module('classy.frs.donation').controller('frs-donationThankYouCtrl', frsDonationThankYouCtrl);

function frsDonationThankYouCtrl($scope, $rootScope, resolvedTransactionSummary, scFlowModalService, scFundraisingPagesService, scFundraisingTeamsService, scTransientsService, scCurrencyService) {
  if (scFlowModalService.activeGroup) {
    scFlowModalService.close();
  }

  $rootScope.pageTitle = $scope.theme.pages['thank-you'].block_sections[1].blocks[0].headline + ' - ' + $scope.campaign.name;
  $scope.page = $scope.theme.pages['thank-you'];

  if (resolvedTransactionSummary) {
    scTransientsService.set('transaction', resolvedTransactionSummary);
  } else if (SC.preview) {
    scTransientsService.set('transaction', {
      comment: 'This is an example donation.',
      frequency: 'one-time',
      fundraising_page_id: null,
      fundraising_team_id: null,
      id: 1,
      total_gross_amount: 100,
      currency_code: _.get($scope, 'campaign.raw_currency_code', scCurrencyService.getDefaultCurrency())
    });
  }

  // Force sync fundraisers & teams
  if (!_.isEmpty($scope.fundraiser) && !_.isUndefined($scope.fundraiser.current)) {
    scFundraisingPagesService.sync($scope.fundraiser.current.id, false, { forceSync: true }).then(function (fp) {
      $scope.fundraiser.current = fp;
    });
  }

  if (!_.isUndefined($scope.team) && !!scFundraisingTeamsService.active) {
    var id = scFundraisingTeamsService.active.current.id;
    scFundraisingTeamsService.sync(id, false, { forceSync: true }).then(function (fp) {
      $scope.team = fp;
    }, function () {});
  }
}
})();