(function(){
'use strict';

frsRoutes.$inject = ["$locationProvider", "$stateProvider"];
function frsRoutes($locationProvider, $stateProvider) {
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });

  $stateProvider.state('frs', {
    abstract: true,
    template: '<div data-ng-class="{ \'sc-flow-modal_blur\': $root.flowModal.ui.blur }" class="public-page"><ui-view></ui-view></div><div data-sc-flow-modal></div>',
    controller: 'frsCtrl',
    resolve: {
      getMetaTags: ['scMetaTags', 'scCampaignsService', function (scMetaTags, scCampaignsService) {
        var appealSet = _.get(scCampaignsService, 'active.current.appealSet');

        scMetaTags.setAppealSetData(appealSet);

        var metaTagsObj = {};
        var videoUrl = void 0;
        var imageUrl = void 0;
        var description = void 0;

        if (!_.isEmpty(appealSet)) {
          videoUrl = appealSet.facebook_video_url;
          imageUrl = appealSet.facebook_image_url;
          description = appealSet.facebook_text ? appealSet.facebook_text : scMetaTags.getDescription();
        } else {
          description = scMetaTags.getDescription();
        }

        var campaignContent = description.length > 300 ? description.substring(0, 297) + '...' : description;

        metaTagsObj = {
          image_url: imageUrl || null,
          video_url: videoUrl || null,
          video_type: videoUrl ? 'application/x-shockwave-flash' : null,
          video_width: videoUrl ? 700 : null,
          video_height: videoUrl ? 394 : null,
          description: campaignContent || null
        };

        return metaTagsObj;
      }]
    }
  });
}

angular.module('classy.frs').config(frsRoutes);
})();