(function(){
'use strict';

dashboardUpdatesCtrl.$inject = ["$scope", "$log", "$q", "$window", "$http", "$state", "mySqlDateTime", "scFroalaOptions", "scFlowModalService", "scCampaignsService", "scFundraisingPagesService", "scFundraisingTeamsService", "scBanner", "accessibilityService"];
var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function dashboardUpdatesCtrl($scope, $log, $q, $window, $http, $state, mySqlDateTime, scFroalaOptions, scFlowModalService, scCampaignsService, scFundraisingPagesService, scFundraisingTeamsService, scBanner, accessibilityService) {
  $scope.activeTab = 'story';

  $scope.setActiveTab = function (activeTab, $event) {
    if ($event) {
      if ($event.keyCode === accessibilityService.CONSTANTS.ENTER_KEY || $event.keyCode === accessibilityService.CONSTANTS.SPACE_KEY) $scope.activeTab = activeTab;
    } else if (!$event) $scope.activeTab = activeTab;
  };

  $scope.activeStyle = function (activeTab) {
    var isActive = activeTab === $scope.activeTab;
    return {
      borderBottomColor: isActive ? $scope.theme.styles.primaryColor : '',
      borderBottomWidth: isActive ? 3 : 0
    };
  };

  $scope.notification = {
    type: 'success'
  };

  $scope.campaign = null;
  $scope.editPage = '';
  $scope.searchUpdates = {
    filter: ''
  };

  $scope.story = {
    title: '',
    body: ''
  };
  $scope.loading = true;

  $scope.sortBy.created_at = 'asc';

  var windowWidth = angular.element($window).width();
  if (Modernizr.prefixed('matchMedia', window)) {
    windowWidth = window.matchMedia('(max-width: 540px)').matches;
  } else {
    windowWidth = angular.element($window).width() <= 540;
  }

  $scope.isMobile = windowWidth;

  /* Determine if we are on individual or team dashboard */
  if ($state.current.name.indexOf('team') > -1) {
    $scope.id = scFundraisingTeamsService.active.current.id;
    $scope.shareLink = 'https://classy.org/team/' + $scope.id + '/updates/';
    $scope.getPostsEndpoint = '/fundraising-teams/' + $scope.id + '/posts';
    $scope.campaign = scFundraisingTeamsService.active;
    $scope.nextPage = 'frs.team.dashboardindex.dashboard.updates';
    $scope.updatePage = 'frs.team.index.post';
  } else if ($state.current.name.indexOf('fundraiser') > -1) {
    $scope.id = scFundraisingPagesService.active.current.id;
    $scope.shareLink = 'https://classy.org/fundraiser/' + $scope.id + '/updates/';
    $scope.getPostsEndpoint = '/fundraising-pages/' + $scope.id + '/posts';
    $scope.campaign = scFundraisingPagesService.active;
    $scope.nextPage = 'frs.fundraiser.dashboardindex.dashboard.updates';
    $scope.updatePage = 'frs.fundraiser.index.post';
  } else {
    $log.error('Error loading fundraiser info.');
  }

  $scope.filterUpdateOptions = ['Most comments', 'Most recent', 'Most shares', 'Most views'];

  $scope.posts = [];

  $scope.activeCampaign = scCampaignsService.active.current;
  var isFundraiserPage = $state.current.name.indexOf('fundraiser') > -1;

  /* Format date string and title of posts */
  $scope.preFilter = function (posts) {
    var filteredPosts = [];
    _.forEach(posts, function (post) {
      var date = mySqlDateTime.toJs(post.created_at).toString();
      var formattedDate = date.split(/\s+/)[1] + ' ' + date.split(/\s+/)[2] + ', ' + date.split(/\s+/)[3];

      var p = {
        body: post.body,
        title: post.title,
        created_at: formattedDate,
        id: post.id,
        comments_count: post.comments_count
      };
      filteredPosts.push(p);
    });

    $scope.posts = filteredPosts;
    $scope.saveList = true;
    $scope.loading = false;
    return filteredPosts;
  };

  $scope.displayDelete = false;
  $scope.showConnectFb = false;

  $scope.froalaOptions = {
    createPost: $scope.isMobile ? scFroalaOptions({ type: 'update-paragraph-mobile' }) : scFroalaOptions({ type: 'update-paragraph' }),
    createTitle: $scope.isMobile ? scFroalaOptions({ type: 'edit-inline-title-mobile' }) : scFroalaOptions({ type: 'edit-inline-title' })
  };

  $scope.resetStory = function () {
    if ($state.current.name.indexOf('team') > -1) {
      $scope.story.title = $scope.activeCampaign.default_team_post_title;
      $scope.story.body = $scope.activeCampaign.default_team_post_body;
    } else if ($state.current.name.indexOf('fundraiser') > -1) {
      $scope.story.title = $scope.activeCampaign.default_page_post_title;
      $scope.story.body = $scope.activeCampaign.default_page_post_body;
    } else {
      $log.error('Error loading fundraiser info.');
    }
  };

  /**
   * Search through all updates by title as user types in searchbar
   */
  var canceler = void 0;

  $scope.$watch('searchUpdates.filter', function (newVal, oldVal) {
    if (newVal !== oldVal) {
      // always cancel previous requests
      if (canceler) {
        canceler.resolve();
      }

      if (newVal !== '') {
        $scope.loading = true;
        canceler = $q.defer();
        var _endpoint = isFundraiserPage ? '/frs-api/fundraising-pages/' : '/frs-api/fundraising-teams/';

        var bodySearch = $http({
          url: _endpoint + $scope.id + '/updates',
          method: 'GET',
          params: {
            filter: 'body*=' + newVal
          },
          timeout: canceler.promise
        });

        var titleSearch = $http({
          url: _endpoint + $scope.id + '/updates',
          method: 'GET',
          params: {
            filter: 'title*=' + newVal
          },
          timeout: canceler.promise
        });

        $q.all([bodySearch, titleSearch]).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              body = _ref2[0],
              title = _ref2[1];

          var combinedArrays = body.data.data.concat(title.data.data);
          $scope.posts = $scope.preFilter(_.uniq(combinedArrays, 'id'));
          $scope.saveList = false;
          $scope.loading = false;
        });
      }
    }
  });

  var endpoint = isFundraiserPage ? '/frs-api/fundraising-pages/' : '/frs-api/fundraising-teams/';

  $http({
    url: endpoint + $scope.id + '/stories',
    method: 'GET'
  }).then(function (response) {
    $scope.story = angular.copy(response.data.data[0]);
    $scope.loading = false;
  });

  $scope.submit = function () {
    $scope.loading = true;
    $http({
      url: '/frs-api/stories/' + $scope.story.id,
      method: 'PUT',
      data: $scope.story
    }).then(function () {
      $scope.loading = false;
      scBanner('Your story has been updated!', 'success');
    }, function () {
      $scope.loading = false;
      scBanner('There was an error publishing your update. Please try again.', 'error');
    });
  };

  /**
   * Save the current list of posts on every initial page change,
   * so that when clearing the search, we can display the correct page
   */
  $scope.$watch('saveList', function (newVal) {
    if (newVal) {
      $scope.defaultPosts = _.cloneDeep($scope.posts);
      $scope.saveList = false;
    }
  });

  $scope.getTwitterUrl = function (post) {
    // strip HTML tags from tweet
    var tweet = post.title.replace(/(<([^>]+)>)/gi, '');
    tweet += ' ' + $scope.shareLink + post.id;
    tweet = encodeURIComponent(tweet);
    var twitterUrl = 'https://twitter.com/intent/tweet?&text=' + tweet;

    $window.open(twitterUrl);
  };

  $scope.confirmDeleteFromList = function (postId) {
    $scope.listDelete = true;
    $scope.deletePostId = postId;
    $scope.modalLoader = true;
    scFlowModalService.open({
      templateUrl: 'frs/shared/supporter/pages/updates/modal-confirm-delete/template',
      context: $scope,
      maxWidth: 520
    }, {
      animate: true
    });
  };

  $scope.displayHelp = function () {
    $scope.showHelp = !$scope.showHelp;
  };

  $scope.confirmDelete = function () {
    $scope.listDelete = false;
    $scope.modalLoader = true;
    scFlowModalService.open({
      templateUrl: 'frs/shared/supporter/pages/updates/modal-confirm-delete/template',
      context: $scope,
      maxWidth: 520
    }, {
      animate: true
    });
  };
}

angular.module('classy.frs').controller('dashboardUpdatesCtrl', dashboardUpdatesCtrl);
})();