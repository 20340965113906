(function(){
'use strict';

scSupporterAdminCtrl.$inject = ["$scope", "$state", "$rootScope", "scFroalaOptions", "scBlocksService", "scMembersService", "scFundraisingPagesService", "scFundraisingTeamsService", "scCampaignsService", "scBanner", "scUserAgent", "scFlowModalService", "CHANNEL", "accessibilityService"];
function scSupporterAdminCtrl($scope, $state, $rootScope, scFroalaOptions, scBlocksService, scMembersService, scFundraisingPagesService, scFundraisingTeamsService, scCampaignsService, scBanner, scUserAgent, scFlowModalService, CHANNEL, accessibilityService) {
  $scope.me = scMembersService.you.current;
  $scope.goTo = goToDashboardPage;
  $scope.logoSrc = getLogoSrc;
  $scope.sortBy = {};
  $scope.changeSort = changeSort;
  $scope.submitButtonStyle = submitButtonStyle;
  $scope.borderedButtonStyle = borderedButtonStyle;
  $scope.removeButtonStyle = removeButtonStyle;
  $scope.coloredBackgroundStyle = coloredBackgroundStyle;
  $scope.coloredStyle = coloredStyle;
  $scope.froalaOptions = scFroalaOptions({ type: 'inline-header' });
  $scope.activeTabStyle = activeTabStyle;
  $scope.orgLogoSrc = getOrgLogo;
  $scope.isFooter = isFooter;
  $scope.getBlock = getBlock;
  setCurrentPage();

  $scope.disableCreate = false;
  $scope.globalState = {
    fbProcessing: false
  };
  var browserSupportKey = 'unsupportedBrowserWarningDismissed';
  var warningHasBeenDismissed = localStorage && localStorage.getItem(browserSupportKey);

  if (scUserAgent.isUnsupportedBrowser() && !warningHasBeenDismissed) {
    var bannerTimeout = true;
    var onClose = function onClose() {
      if (window.localStorage) {
        localStorage.setItem(browserSupportKey, 'true');
      }
    };

    scBanner('Your current web browser may not support all the features on this site. We recommend updating your web browser or using Google Chrome for the best experience', 'neutral', bannerTimeout, 0, null, onClose);
  }

  function setCurrentPage() {
    if ($state.current.name.indexOf('fundraiser') > -1) {
      $scope.pageType = 'fundraiser';
      $scope.currentPage = scFundraisingPagesService.active.current;
      $scope.pageAlias = scFundraisingPagesService.active.current;
      $scope.page = $scope.theme.pages.fundraiser;
      $scope.active = scFundraisingPagesService.active;
    } else if ($state.current.name.indexOf('team') > -1) {
      $scope.pageType = 'team';
      $scope.currentPage = scFundraisingTeamsService.active.current;
      $scope.page = $scope.theme.pages.team;
      $scope.active = scFundraisingTeamsService.active;
    } else {
      $scope.pageType = 'campaign';
      $scope.currentPage = scCampaignsService.active.current;
      $scope.active = scCampaignsService.active;
    }
  }

  var customFooterId = void 0;

  if ($scope.page) {
    var bodySection = _.filter($scope.page.block_sections, function (block) {
      return block.name == 'body';
    })[0];

    if (bodySection.blocks.length > 1) {
      customFooterId = bodySection.blocks[bodySection.blocks.length - 1].id;
    }
  }

  function isFooter(block) {
    return block.id == customFooterId || block.type == 'footer';
  }

  function goToDashboardPage(page, $event) {
    if ($event) {
      if ($event.keyCode === accessibilityService.CONSTANTS.ENTER_KEY || $event.keyCode === accessibilityService.CONSTANTS.SPACE_KEY) $state.go('frs.' + $scope.pageType + '.dashboardindex.dashboard.' + page);
    } else if (!$event) $state.go('frs.' + $scope.pageType + '.dashboardindex.dashboard.' + page);
  }

  function getOrgLogo() {
    return $scope.organization.current.thumbnail_medium;
  }

  function getLogoSrc() {
    if ($scope.pageType === 'fundraiser') {
      return scFundraisingPagesService.getPagePhoto($scope.currentPage);
    } else if ($scope.pageType === 'team') {
      return scFundraisingTeamsService.getTeamPhoto($scope.currentPage);
    }
    return undefined;
  }

  function changeSort(newSort) {
    if ($scope.sortBy[newSort]) {
      $scope.sortBy[newSort] = $scope.sortBy[newSort] == 'asc' ? 'desc' : 'asc';
    } else {
      var sortObj = {};
      sortObj[newSort] = 'asc';
      $scope.sortBy = sortObj;
    }
  }

  function borderedButtonStyle() {
    return {
      borderColor: $scope.theme.styles.primaryColor,
      color: $scope.theme.styles.primaryColor
    };
  }

  function submitButtonStyle() {
    var disabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

    var parsedColors = $scope.theme.styles.primaryColor.split(', ');
    var red = parseInt(parsedColors[0].split('(')[1], 10);
    var green = parseInt(parsedColors[1], 10);
    var blue = parseInt(parsedColors[2].split(')')[0], 10);

    // yiq algorithm
    var yiq = (red * 299 + green * 587 + blue * 114) / 1000;

    return {
      backgroundColor: disabled ? '#f8f8f8' : $scope.theme.styles.primaryColor,
      color: yiq >= 128 ? 'black' : 'white',
      borderColor: $scope.theme.styles.primaryColor
    };
  }

  function coloredBackgroundStyle() {
    return { backgroundColor: $scope.theme.styles.primaryColor };
  }

  function coloredStyle() {
    return { color: $scope.theme.styles.primaryColor };
  }

  function removeButtonStyle() {
    return {
      backgroundColor: '#f0f0f0',
      color: 'rgba(0, 0, 0, 0.54)',
      borderColor: '#f0f0f0'
    };
  }

  function activeTabStyle(name) {
    return {
      'border-bottom': $state.includes('**.' + name + '.**') ? '4px solid ' + $scope.theme.styles.primaryColor : 0
    };
  }

  function getBlock(type) {
    return scBlocksService.getIdByType(type);
  }

  $scope.openFBSuccessModal = function () {
    scFlowModalService.open({
      templateUrl: 'frs/shared/supporter/pages/overview/success-modal',
      context: $rootScope,
      maxWidth: 400
    }, { animate: true });
  };
  var launchFBFundrasingPageModal = function launchFBFundrasingPageModal() {
    scFlowModalService.open({
      templateUrl: 'frs/shared/supporter/pages/facebook/processing-modal',
      context: $scope,
      maxWidth: 520
    }, {
      animate: true,
      closable: true,
      closeOnClickOut: false,
      elementToFocusOnClose: document.activeElement
    });
  };
  $scope.createFBFundraisingPage = function (page) {
    $scope.globalState.fbProcessing = true;
    launchFBFundrasingPageModal();
    scFundraisingPagesService.active.createChannelFundraisingPage(CHANNEL.FB).then(function () {
      $scope.disableCreate = true;
      $scope.globalState.fbProcessing = false;
      scFlowModalService.close();
      $scope.openFBSuccessModal();
      if (page) {
        $scope.goTo('facebook');
      } else {
        $state.reload();
        $scope.$apply();
      }
    }).catch(function () {
      $scope.globalState.fbProcessing = false;
      scFlowModalService.close();
      $scope.disableCreate = false;
    });
  };
}

angular.module('classy.frs').controller('scSupporterAdminCtrl', scSupporterAdminCtrl);
})();