(function(){
'use strict';

supporterOverviewDataFactory.$inject = ["ChartistDataFactory", "$filter"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function supporterOverviewDataFactory(ChartistDataFactory, $filter) {
  return {
    get: function get(campaign, metrics, totalRaised, currencyCode) {
      return ChartistDataFactory({
        data: calculateGraphValues(getDataForChartDays(metrics), totalRaised || 0),
        labels: getChartLabels(),
        withPointLabels: true,
        labelInterpolationFnc: formatCurrency.bind(undefined, $filter('scFormatCurrency'), campaign, currencyCode)
      });
    },
    getMetricData: getMetricData,
    // included here for testing purposes only, please use the `get` or `getMetricData` methods for
    // interfacing with this factory
    calculateGraphValues: calculateGraphValues,
    getDataForChartDays: getDataForChartDays,
    getChartLabels: getChartLabels,
    getChartKeys: getChartKeys,
    formatCurrency: formatCurrency
  };
}

/*
 * Metrics data is delivered by the backend as a series of metrics for individual days.
 * However, this data is not aggregated - the numbers within are not a representation of an overall
 * increase, but rather just the change from an individual day (IE, the transactions for a day).
 * The backend also delivers to us an aggregate object which contains the overall totals for the
 * given data set.
 *
 * As we have no guarantees that the data set will contain all of the historical data (as opposed
 * to just the most recent set of data), we cannot simply add up all of the numbers to make each
 * graph point. Instead, we first filter out any dates which come before the dates on the graph.
 * After that, we have to take each individual value and convert it into an offset from the total.
 *
 * Once we've done that, we *subtract* each offset value from the total amount raised in order to
 * generate the correcy y-value for the graph.
 */
var calculateGraphValues = function calculateGraphValues(metrics, totalRaised) {
  return metrics
  // moving backwards, calculate the offset from the total for each calendar day.
  // the offset for any given day from the total raised can be calculated as:
  // offset[day] = change[day + 1] + offset[day + 1]
  // That is, the total from a day is equal to whatever the total is at the end of the next day,
  // minus however much is added to the total the next day.
  // Because we're iterating backwards over the array to calculate these values, we make sure to
  // prepend the newly calculated offset (rather than appending it)
  .reduceRight(function (acc, value) {
    return [value + acc[0]].concat(_toConsumableArray(acc));
  }, [0])
  // Once we've calculated what the offset from the total for each day is, we calculate the total
  // for each day.
  // total[day] = totalRaised - offset[day + 1]
  .map(function (value) {
    return totalRaised - value;
  })
  // remove the first entry in the list, because it would represent the offset to a day we do not
  // show on the graph (5 days ago)
  .slice(1);
};

var getDataForChartDays = function getDataForChartDays(metrics) {
  return getChartKeys()
  // for our list of metrics, find the individual item which corresponds to each day we're
  // going to show on the graph. if data for that item can't be found, use an empty object in its
  // place
  .map(function (day) {
    return _.find(metrics || [], { start_date: day }) || {};
  })
  // read all of the values from the data we found for each day
  .map(getMetricData);
};

// get the data for an individual metric
// should return the parsed data for each metric or zero if passed in an empty object
var getMetricData = function getMetricData(_ref) {
  var _ref$gross_amount = _ref.gross_amount,
      grossAmount = _ref$gross_amount === undefined ? 0 : _ref$gross_amount,
      _ref$progress_bar_amo = _ref.progress_bar_amount,
      progressBarAmount = _ref$progress_bar_amo === undefined ? 0 : _ref$progress_bar_amo;
  return Math.round(parseFloat(progressBarAmount || grossAmount));
};

var formatCurrency = function formatCurrency(scFormatCurrency, campaign, currencyCode, value) {
  if (campaign.multiCurrencyDisabled()) {
    return '$' + value;
  }

  return scFormatCurrency(value, currencyCode, 0);
};

var getChartDays = function getChartDays() {
  return [moment().subtract(4, 'days'), moment().subtract(3, 'days'), moment().subtract(2, 'days'), moment().subtract(1, 'days'), moment()];
};

var getChartLabels = function getChartLabels() {
  return getChartDays().map(function (day) {
    return day.format('MM/DD');
  });
};
// YYYY-MM-DD
var getChartKeys = function getChartKeys() {
  return getChartDays().map(function (day) {
    return day.format('YYYY-MM-DD');
  });
};

angular.module('classy.frs').factory('SupporterOverviewDataFactory', supporterOverviewDataFactory);
})();