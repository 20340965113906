(function(){
'use strict';

deletePostModalCtrl.$inject = ["$log", "$scope", "$rootScope", "$http", "$stateParams", "scFlowModalService"];
function deletePostModalCtrl($log, $scope, $rootScope, $http, $stateParams, scFlowModalService) {
  $scope.delete = function () {
    if ($stateParams.postId) {
      $http.delete('/frs-api/updates/' + $stateParams.postId).then(function () {
        $scope.goTo('updates.manage');
      }, function (err) {
        $log.error('Sorry, there was an error deleting your post', err);
      });
    } else {
      $scope.goTo('updates.manage');
    }
  };

  $scope.listDelete = function () {
    $scope.modalLoading = true;
    $http.delete('/frs-api/updates/' + $scope.$parent.deletePostId).then(function () {
      $scope.modalLoading = false;
      _.remove($scope.$parent.posts, function (post) {
        return post.id == $scope.$parent.deletePostId;
      });

      _.remove($scope.$parent.defaultPosts, function (post) {
        return post.id == $scope.$parent.deletePostId;
      });

      $rootScope.SC.status.banner = {
        type: 'success',
        msg: 'Your post was successfully deleted!'
      };
      $scope.$root.flowModal.close(false);
    }, function () {
      $scope.modalLoading = false;
      $rootScope.SC.status.banner = {
        type: 'error',
        msg: 'Sorry, there was an error deleting your post'
      };
      $scope.$root.flowModal.close(false);
    });
  };

  $scope.action = function (action) {
    $scope.modalLoading = true;
    $scope.modalAction(action).finally(function () {
      $scope.modalLoading = false;
      scFlowModalService.close();
    });
  };
}

angular.module('classy.frs').controller('deletePostModalCtrl', deletePostModalCtrl);
})();