(function(){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

angular.module('classy.frs').component('supporterOverviewChart', {
  template: '\n    <div ng-if="$ctrl.hasData">\n      <chartist \n        chart-data="$ctrl.graphData.data"\n        chart-color="$ctrl.chartColor"\n        options="$ctrl.graphData.options"></chartist>\n    </div>\n  ',
  bindings: {
    metrics: '<',
    currencyCode: '<',
    chartColor: '<',
    totalRaised: '<'
  },
  controller: function () {
    SupporterOverviewChart.$inject = ["scCampaignsService", "SupporterOverviewDataFactory"];
    function SupporterOverviewChart(scCampaignsService, SupporterOverviewDataFactory) {
      _classCallCheck(this, SupporterOverviewChart);

      this.campaignsService = scCampaignsService;
      this.getData = SupporterOverviewDataFactory.get;
    }

    _createClass(SupporterOverviewChart, [{
      key: '$onInit',
      value: function $onInit() {
        this.renderGraph();
      }
    }, {
      key: '$onChanges',
      value: function $onChanges() {
        this.renderGraph();
      }
    }, {
      key: 'renderGraph',
      value: function renderGraph() {
        if (this.campaignsService && this.campaignsService.active && this.totalRaised !== undefined && this.metrics && this.currencyCode) {
          this.graphData = this.getData(this.campaignsService.active, this.metrics, this.totalRaised, this.currencyCode);

          if (!this.hasData) {
            this.hasData = true;
          }
        }
      }
    }]);

    return SupporterOverviewChart;
  }()
});
})();