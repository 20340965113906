(function(){
'use strict';

angular.module('classy.frs.donation').controller('frsDonationEmbeddedGivingCtrl', ["$document", "$scope", "$state", "$stateParams", "scCampaignsService", "EmbeddedGiving", "EmbeddedGivingUtil", function ($document, $scope, $state, $stateParams, scCampaignsService, EmbeddedGiving, EmbeddedGivingUtil) {
  if (EmbeddedGiving.hasEmbeddedGiving(scCampaignsService.active)) {
    EmbeddedGiving.init({
      isEnabled: !!$stateParams.eg,
      param: $stateParams.egp
    });
    $scope.bodySection = _.find($scope.page.block_sections, { name: 'body' });

    var body = $($document[0].body);
    if (!body.hasClass('eg-event-modal') && EmbeddedGivingUtil.isInIframe(scCampaignsService.active) && (scCampaignsService.active.getEmbeddedGiving() === 'modal' || scCampaignsService.active.getEmbeddedGiving() === 'inline')) {
      body.addClass('eg-event-modal');
    }
  } else {
    $state.go('frs.donation.checkout', {
      eg: null,
      egp: null,
      egfa: null, // Full Address
      egrn: null, // Recurring Nudge
      egga4: null // GA4 "Bridge"
    });
  }
}]);
})();