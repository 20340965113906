(function(){
'use strict';

dashboardStoryEditCtrl.$inject = ["$rootScope", "$scope", "$http", "$stateParams"];
function dashboardStoryEditCtrl($rootScope, $scope, $http, $stateParams) {
  $scope.loading = true;

  $http.get('/frs-api/stories/' + $stateParams.storyId).then(function (response) {
    $scope.loading = false;
    $scope.story = response.data;
  }, function (err) {
    $scope.loading = false;

    var errMsg = 'Sorry, something went wrong. You will be redirected back to your story...';
    if (err.status == 404) {
      errMsg = 'Sorry, the story could not be found. You will be redirected back to your';
    }

    $rootScope.SC.status.banner = {
      type: 'error',
      msg: errMsg
    };

    // Redirect user to updates page
    setTimeout(function () {
      $scope.goTo('updates.manage');
    }, 5000);
  });

  $scope.submit = function () {
    $http.put('/frs-api/stories/' + $stateParams.storyId, $scope.story).then(function () {
      $rootScope.SC.status.banner = {
        type: 'success',
        msg: 'Your story has been updated!'
      };

      // Redirect user to updates page
      setTimeout(function () {
        $scope.goTo('updates.manage');
      }, 5000);
    }, function () {
      $rootScope.SC.status.banner = {
        type: 'error',
        msg: 'Sorry, this is not yet available.'
      };
    });
  };
}

angular.module('classy.frs').controller('dashboardStoryEditCtrl', dashboardStoryEditCtrl);
})();